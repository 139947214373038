import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import LogoDark from "../assets/images/logo-dark.png";
import './css/BlogPost.css';
import Footer from "./Footer";
import FloatingCookies from "./FloatingCookies";
import FloatingWhatsAppButton from "./FloatingWhatsAppButton";
import { ProgressSpinner } from 'primereact/progressspinner';

export default function BlogPost() {
  const [isOpen, setMenu] = useState(true);
  const [blog, setBlog] = useState([]);
  const { title } = useParams();
  const decodedTitle = decodeURIComponent(title);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await fetch('https://servidor-backend-oliveira-silva.vercel.app/api/blog');
        const data = await response.json();
        if (isMounted) {
          setBlog(data);
        }
      } catch (error) {
        console.error('Erro ao buscar posts do blog:', error);
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  const post = blog.find(item => item.url === decodedTitle);

  // Remova a condicional fora do useEffect e coloque a lógica condicional dentro dele
  useEffect(() => {
    const updateLinks = () => {
      const links = document.querySelectorAll(".principal a");
      links.forEach(link => {
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
      });
    };

    if (post) {
      updateLinks();
    }
  }, [post]);

  if (!post) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ProgressSpinner />
      </div>
    );
  }

  const toggleMenu = () => {
    setMenu(!isOpen);
  };

  const closeMenu = () => {
    setMenu(true);
  };

  return (
    <>
      <Helmet>
        <title>{post.title}</title>
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="og:image" content={post.image} />
      </Helmet>

      <div>
        <nav className="navbar" style={{ backgroundColor: 'white' }} id="navbar">
          <div className="container flex flex-wrap items-center justify-end">
            <ScrollLink className="nav-item">
              <img src={LogoDark} className="inline-block dark:hidden" alt="Logo" />
            </ScrollLink>
            <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
              <ul className="list-none menu-social mb-0">
                <li className="inline ms-1">
                  <a
                    href="mailto:contato@advocaciaoliveiraesilva.adv.br"
                    className="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white"
                  >
                    <i className="uil uil-mailbox"></i>
                  </a>
                </li>
                <li className="inline ms-1">
                  <a
                    href="https://www.instagram.com/adv_oliveiraesilva"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white"
                  >
                    <i className="uil uil-instagram"></i>
                  </a>
                </li>
              </ul>
              <button
                type="button"
                className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                onClick={toggleMenu}
              >
                <i className="mdi mdi-menu mdi-24px"></i>
              </button>
            </div>

            <div className={`${isOpen ? 'hidden' : 'block'} navigation lg_992:order-1 lg_992:flex`} id="menu-collapse">
              <ul className="navbar-nav" id="navbar-navlist">
                <RouterLink className="nav-item" to="/" onClick={closeMenu}>
                  <span className="nav-link">Início</span>
                </RouterLink>
                <RouterLink className="nav-item" to="/" onClick={closeMenu}>
                  <span className="nav-link">Nossa História</span>
                </RouterLink>
                <RouterLink className="nav-item" to="/" onClick={closeMenu}>
                  <span className="nav-link">Áreas de Atuação</span>
                </RouterLink>
                <RouterLink className="nav-item" to="/" onClick={closeMenu}>
                  <span className="nav-link">Time</span>
                </RouterLink>
                <RouterLink className="nav-item" to="/" onClick={closeMenu}>
                  <span className="nav-link">Publicações</span>
                </RouterLink>
                <RouterLink className="nav-item" to="/" onClick={closeMenu}>
                  <span className="nav-link">Vamos Conversar?</span>
                </RouterLink>
              </ul>
            </div>
          </div>
        </nav>

        <section className="relative md:py-24 py-16" id="blog-post">
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
              <div style={{ display: 'flex' }}>
                <RouterLink to="/" className="text-blue-500 hover:text-blue-700">
                  &#8592; Voltar
                </RouterLink>
              </div>
              <div className="flex items-center justify-between">
                <h2 className="title-post">{post.title}</h2>
              </div>
            </div>
            <div className="principal">
              <div className="bloco">
                <img
                  src={post.image}
                  alt={post.title}
                  style={{ borderRadius: 5, float: "left", marginRight: "10px" }}
                  className="mb-6"
                />
              </div>
              <p className="mb-4" dangerouslySetInnerHTML={{ __html: post.description }}></p>
            </div>
          </div>
        </section>

        <Footer />
        <FloatingWhatsAppButton />
        <FloatingCookies />
      </div>
    </>
  );
}
