import React from 'react';
import './css/FloatingWhatsAppButton.css';


const FloatingWhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=5531998626959', '_blank');
  };
  const WhatsAppImage = 'https://www.advocaciaoliveiraesilva.adv.br/assets/images/icons/whatsapp-icone-2.png';
  
  return (
    <div className="floating-button" onClick={handleWhatsAppClick}>
      <img src={WhatsAppImage} alt="WhatsApp" />
    </div>
  );
};

export default FloatingWhatsAppButton;
