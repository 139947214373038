import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './css/ModalStart.css';
import './css/FloatingCookies.css';

const FloatingCookies = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalCookies, setShowModalCookies] = useState(false);

  useEffect(() => {
    Cookies.get();
  }, []);

  const handleConsentCookies = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowModalCookies(false);
    Cookies.set('advocaciaoliveiraesilva', 'advocaciaoliveiraesilva-cookie', { expires: 7, path: '/' });
  };

  const handleConsent = () => {
    localStorage.setItem('privacyConsent', 'true');
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1); // Expira após 1 dia
    localStorage.setItem('consentExpiration', expirationDate.toISOString());
    setShowModal(false);
    setShowModalCookies(true);
  };


  const handleCookiesClick = () => {
    setShowModal(true);
  };

  return (
    <>
    <div
      className="floating-button-cookies"
      onClick={handleCookiesClick}
    >
      <img
        src='/assets/images/icons/cookie-bite-solid.svg'
        alt="Cookies"
        style={{
          borderRadius: '50%',
          width: '45px',
          fill: 'red',
        }}
        className="orange-fill" // Adicione a classe aqui
      />
    </div>

{showModal && (
  <div className="termprivacy fixed bottom-0 left-0 right-0 bg-gray-900 text-white py-4 px-6">
    <div className="termprivacy-modal-content">
      <h3 className="text-xl font-bold mb-2">Termos de Privacidade</h3>
      <p className="text-modal mb-4">
      Agradecemos por visitar nosso site <br/> Antes de prosseguir, gostaríamos de informar que implementamos uma política de privacidade para garantir a segurança e proteção dos seus dados pessoais. É importante que você leia atentamente os termos seguir, pois ao utilizar nosso site, você concorda com as políticas e práticas descritas abaixo.
      </p>
      <p className="text-modal mb-4">
        Ao clicar em "Aceitar", você confirma que leu e concorda com nossos 
        <a href="./term-privacy/termo-de-privacidade-oliveira-e-silva.pdf" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline ml-1">
          Termos de Privacidade
        </a>.
      </p>
      <button
        onClick={handleConsent}
        className="bg-orange-600 hover:bg-orange-700 text-white py-2 px-4 rounded"
      >
        Aceitar
      </button>
    </div>
  </div>
)}
{showModalCookies && (
        <div className="cookie-modal fixed bottom-0 left-0 right-0 bg-gray-900 text-white py-4 px-6">
          <div className="cookie-modal-content">
            <h3 className="text-xl font-bold mb-2">Utilizamos cookies em nosso site</h3>
            <p className="text-modal mb-4">
              Utilizamos cookies para melhorar sua experiência de navegação e personalizar o conteúdo e anúncios. Ao continuar navegando neste site, você concorda com o uso de cookies.
            </p>
            <button
              onClick={handleConsentCookies}
              className="bg-orange-600 hover:bg-orange-700 text-white py-2 px-4 rounded"
            >
              Aceitar
            </button>
          </div>
        </div>
      )}
</>
  );
};

export default FloatingCookies;
