import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoDark from "../../assets/images/logo-dark.png";
import { FaEdit, FaTrash, FaStar } from 'react-icons/fa';
import { jwtDecode } from "jwt-decode";
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './css/admin.css';
import SidebarLeft from "./component/Sidebar-Left";

export default function PageAdmin() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [sessionExpiration, setSessionExpiration] = useState(0);
  const [tempSession, setTempSession] = useState('');
  const [userName, setUserName] = useState('');
  const [selectedItem, setSelectedItem] = useState('avaliacoes');
  const [tableData, setReviews] = useState([]);
  const [postData, setBlogPosts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const URL = 'https://servidor-backend-oliveira-silva.vercel.app';

  const [editingItem, setEditingItem] = useState({ description: '' });


  const handleEditItem = (item) => {
    const imageUrl = item.image;
    const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
  
    const newItem = {
      ...item,
      image: fileName 
    };
    setEditingItem(newItem);
    setShowModal(true);
  };
  

  const handleDeleteItem = (item) => {
    setEditingItem(item);
    setShowDeleteConfirmation(true);
  };

  const handleAddNewItem = () => {
    setEditingItem(null);
    setShowModal(true);
  };

  const handleSaveChanges = () => {
    if (editingItem) {
      if (selectedItem === 'avaliacoes') {
        const url = `${URL}/api/avaliation-function`;
        const method = 'POST';
        fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(editingItem),
        })
          .then(response => {
            if (response.ok) {
              fetch(`${URL}/api/avaliations`)
                .then(response => response.json())
                .then(data => setReviews(data))
                .catch(error => console.error('Erro ao buscar avaliações:', error));
  
              setShowModal(false);
              setEditingItem(null);
            } else {
              console.error('Erro ao salvar avaliação:', response.statusText);
            }
          })
          .catch(error => console.error('Erro ao salvar avaliação:', error));
      } else if (selectedItem === 'publicacoes') {
        const url = `${URL}/api/blog-function`;
        const method = 'POST';
        fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(editingItem),
        })
          .then(response => {
            if (response.ok) {
              fetch(`${URL}/api/blog`)
                .then(response => response.json())
                .then(data => setBlogPosts(data))
                .catch(error => console.error('Erro ao buscar posts do blog:', error));
  
              setShowModal(false);
              setEditingItem(null);
            } else {
              console.error('Erro ao salvar publicação:', response.statusText);
            }
          })
          .catch(error => console.error('Erro ao salvar publicação:', error));
      }
    }
  };
  
  const handleDeleteConfirmed = () => {
    if (editingItem) {
      if (selectedItem === 'avaliacoes') {
        fetch(`${URL}/api/avaliation-del/${editingItem.id}`, {
          method: 'GET',
        })
          .then(response => {
            if (response.ok) {
              fetch(`${URL}/api/avaliations`)
                .then(response => response.json())
                .then(data => setReviews(data))
                .catch(error => console.error('Erro ao buscar avaliações:', error));
  
              setShowDeleteConfirmation(false);
              setEditingItem(null);
            } else {
              console.error('Erro ao excluir avaliação:', response.statusText);
            }
          })
          .catch(error => console.error('Erro ao excluir avaliação:', error));
      } else if (selectedItem === 'publicacoes') {
        fetch(`${URL}/api/blog-del/${editingItem.id}`, {
          method: 'GET',
        })
          .then(response => {
            if (response.ok) {
              fetch(`${URL}/api/blog`)
                .then(response => response.json())
                .then(data => setBlogPosts(data))
                .catch(error => console.error('Erro ao buscar posts do blog:', error));
  
              setShowDeleteConfirmation(false);
              setEditingItem(null);
            } else {
              console.error('Erro ao excluir publicação:', response.statusText);
            }
          })
          .catch(error => console.error('Erro ao excluir publicação:', error));
      }
    }
  };
  
  

  const handleItemSelected = (itemId) => {
    setSelectedItem(itemId);
  };

  useEffect(() => {
    fetch(`${URL}/api/avaliations`)
      .then(response => response.json())
      .then(data => setReviews(data))
      .catch(error => console.error('Erro ao buscar avaliations:', error));
    fetch(`${URL}/api/blog`)
      .then(response => response.json())
      .then(data => setBlogPosts(data))
      .catch(error => console.error('Erro ao buscar posts do blog:', error));
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      if (Date.now() < decodedToken.exp * 1000) {
        setIsLoading(false); 
        setSessionExpiration(decodedToken.exp * 1000); 
        setUserName(decodedToken.name);
      } else {
        navigate("/auth");
      }
    } else {
      navigate("/auth");
    }
  
    const intervalId = setInterval(() => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        navigate("/auth");
        clearInterval(intervalId);
        return;
      }
      const decodedToken = jwtDecode(token);
      if (!decodedToken || Date.now() >= decodedToken.exp * 1000) {
        navigate("/auth");
        clearInterval(intervalId);
      } else {
        setSessionExpiration(decodedToken.exp * 1000);
        const timeLeft = Math.max(0, Math.ceil((decodedToken.exp * 1000 - Date.now()) / 1000)); // Corrigido para usar o tempo de expiração do token
        const minutesLeft = Math.floor(timeLeft / 60);
        const secondsLeft = timeLeft % 60;
        setTempSession(`${minutesLeft}:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}`);
      }
    }, 1000);
  
    return () => clearInterval(intervalId); 
  }, [navigate]);
  

  if (isLoading) {
    return null; 
  }

  return (
    <>
      <nav style={{backgroundColor: "whitesmoke"}} className="navbar-top">
          <div className="container flex flex-wrap items-center navbar-top">
            <div className="flex items-center flex-shrink-0 mr-6">
              <img src={LogoDark}  alt="Logo" />
            </div>
            <div>
              <span className="text-sm font-semibold temp-session">{tempSession}</span>
              <span className="text-sm mr-4">{`Olá, ${userName}`}</span>
              <Link to="/" className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
                Sair
              </Link>
            </div>
          </div>
      </nav>
      <SidebarLeft handleItemSelected={handleItemSelected} />
      <div className="grid-container">
        {/* Avaliations */}
        <div className={`grid-item ${selectedItem === 'avaliacoes' ? 'selected' : 'avaliacoes'}`} onClick={() => { setSelectedItem('avaliacoes'); handleItemSelected('avaliacoes'); }}>
          {selectedItem === 'avaliacoes' && (
            <div>
              <div style={{display: 'flex'}}><h1 style={{fontSize: 20, fontWeight: "bold", marginBottom: 20, marginRight: 4}}>Tabela de Avaliations </h1><FaStar size={20} color="orange"/></div>
              <div style={{marginBottom: 10}}>
                <button onClick={handleAddNewItem} className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
                  Adicionar
                </button>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Rating</th>
                    <th>Temp</th>
                    <th>Description</th>
                    <th>Function</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item) => (
                    <tr key={item.id}>
                      <td>{item.rating}</td>
                      <td>{item.relative_time_description}</td>
                      <td>{item.text}</td>
                      <td>
                        <button className="functions" onClick={() => handleEditItem(item)}><FaEdit /></button>
                        <button className="functions" onClick={() => handleDeleteItem(item)}><FaTrash /></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Publicações */}
        <div className={`grid-item ${selectedItem === 'publicacoes' ? 'selected' : ''}`} onClick={() => { setSelectedItem('publicacoes'); handleItemSelected('publicacoes'); }}>
          {selectedItem === 'publicacoes' && (
            <div>
              <div style={{display: 'flex'}}><h1 style={{fontSize: 20, fontWeight: "bold", marginBottom: 20, marginRight: 4}}>Tabela de Publicações</h1><FaStar size={20} color="orange"/></div>
              <div style={{marginBottom: 10}}>
                <button onClick={handleAddNewItem} className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
                  Adicionar
                </button>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Título</th>
                    <th>Imagem</th>
                    <th>Function</th>
                  </tr>
                </thead>
                <tbody>
                  {postData.map((post) => (
                    <tr key={post.id}>
                      <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px'}}>{post.title}</td>
                      <td><img src={post.image} style={{ width: '100px', height: 'auto', borderRadius: 5 }} /></td>
                      <td>
                        <button className="functions" onClick={() => handleEditItem(post)}><FaEdit /></button>
                        <button className="functions" onClick={() => handleDeleteItem(post)}><FaTrash /></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>


      </div>

      {showModal && (
        <div className="fixed inset-0 overflow-hidden flex justify-center items-center z-50">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={() => setShowModal(false)}></div>
          <div className="absolute bg-white p-8 rounded-lg shadow-lg max-w-md" style={{ marginTop: "5%", maxHeight: '82vh' }}>
            <button onClick={() => setShowModal(false)} className="absolute top-4 right-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="mb-4">
              {/* Adicionando inputs com base no tipo de item */}
              {selectedItem === 'avaliacoes' && (
                <>
                  <h2 className="text-lg mr-4 mb-4 font-bold">Avaliações</h2>
                  <input type="text" placeholder="Rating" className="input mb-4 border rounded-lg w-full h-10 px-4 py-2" value={editingItem ? editingItem.rating : ''} onChange={(e) => setEditingItem({ ...editingItem, rating: e.target.value })} />
                  <input type="text" placeholder="Temp" className="input mb-4 border rounded-lg w-full h-10 px-4 py-2" value={editingItem ? editingItem.relative_time_description : ''} onChange={(e) => setEditingItem({ ...editingItem, relative_time_description: e.target.value })} />
                  <textarea placeholder="Description" className="input mb-4 border rounded-lg w-full h-24 px-4 py-2" value={editingItem ? editingItem.text : ''} onChange={(e) => setEditingItem({ ...editingItem, text: e.target.value })}></textarea>
                </>
              )}
              {selectedItem === 'publicacoes' && (
                <>
                  <h2 className="text-lg mr-4 mb-4 font-bold">Publicações</h2>
                  <input type="text" placeholder="Título" className="input mb-4 border rounded-lg w-full h-10 px-4 py-2" value={editingItem ? editingItem.title : ''} onChange={(e) => setEditingItem({ ...editingItem, title: e.target.value })} />
                  <input type="text" placeholder="URL Imagem" className="input mb-4 border rounded-lg w-full h-10 px-4 py-2" value={editingItem ? editingItem.image : ''} onChange={(e) => setEditingItem({ ...editingItem, image: e.target.value })} />
                  <ReactQuill
                    value={editingItem ? editingItem.description : ''}
                    onChange={(content, delta, source, editor) => setEditingItem({ ...editingItem, description: content })}
                    theme="snow"
                    style={{ height: '150px', marginBottom: '50px' }}
                  />
                </>
              )}
            </div>
            {/* Botão para salvar */}
            <button onClick={() => handleSaveChanges()} className="btn bg-orange-600 mt-4 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-sm rounded-full">{editingItem ? 'Salvar Alterações' : 'Adicionar Item'}</button>
          </div>
        </div>
      )}



      {showDeleteConfirmation && (
        <div className="fixed inset-0 overflow-hidden flex justify-center items-center z-50">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={() => setShowDeleteConfirmation(false)}></div>
          <div className="absolute bg-white p-8 rounded-lg shadow-lg max-w-md" style={{ marginTop: "5%", maxHeight: '82vh' }}>
            <button onClick={() => setShowDeleteConfirmation(false)} className="absolute top-4 right-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="flex items-center mb-4">
              <div>
                <h2 className="text-lg mr-4 font-bold">Tem certeza que deseja excluir este item?</h2>
              </div>
            </div>
            <button onClick={() => handleDeleteConfirmed()} className="btn bg-red-600 mt-4 mr-2 hover:bg-red-700 border-red-600 hover:border-red-700 text-white btn-sm rounded-full">Sim</button>
            <button onClick={() => setShowDeleteConfirmation(false)} className="btn bg-gray-600 mt-4 hover:bg-gray-700 border-gray-600 hover:border-gray-700 text-white btn-sm rounded-full">Não</button>
          </div>
        </div>
      )}


    </>
  );
}
