import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import avatar1 from "../assets/images/client/01.jpeg";
import avatar2 from "../assets/images/client/02.jpeg";
import avatar3 from "../assets/images/client/03.jpeg";
import avatar4 from "../assets/images/client/04.jpeg";

export default function Team() {
    const [currentAvatar, setCurrentAvatar] = useState(avatar1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentAvatar(prevAvatar => prevAvatar === avatar1 ? avatar2 : avatar1);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const openModal = (member) => {
        setSelectedMember(member);
        setModalIsOpen(true);
    };
    
    const closeModal = () => {
        setModalIsOpen(false);
    };


    const team = [
        {
            name: 'Adriano Guilherme Silva',
            profile: avatar1,
            profile2: avatar2,
            tel: '37999361354',
            designation: 'Advogado. Especialista pela FESMP-MG e FMP-RS ',
            biografy: `Adriano Guilherme Silva, advogado no Escritório Oliveira e Silva Advocacia, situado em Sete Lagoas, Minas Gerais, e inscrito na OAB/MG sob o nº 201.547, tem uma trajetória profissional destacada e diversificada, enriquecida por especializações de grande relevância. Seu percurso profissional começou com um estágio em um gabinete de Delegado de Polícia Civil em Minas Geraus, o que lhe proporcionou uma enriquecedora base sobre o sistema policial brasileiro e políticas públicas de segurança pública. Esta experiência inicial forjou uma sólida compreensão dos desafios e estruturas dentro da segurança pública, servindo como um alicerce para sua carreira.

            Com dedicação e competência, Adriano foi aprovado em um exame de seleção pública para estagiário de graduação em Direito, atuando no Gabinete do Promotor de Justiça do MPMG, em sua cidade natal, em uma Promotoria de Justiça Única. Enquanto esteve no setor público, adquiriu experiência valiosa sobre o sistema jurídico brasileiro, complementando sua formação e perspectiva sobre o direito.
            
            Em busca de ampliar ainda mais suas experiências, Adriano aventurou-se no setor privado, desempenhando a função de Assessor Executivo em uma empresa de mineração em Minas Gerais. Lá, ele adquiriu experiência significativa em questões legais corporativas, aprofundando seu conhecimento em áreas que transcenderam o escopo tradicional do direito.
            
            Seu desejo contínuo por aprofundamento e especialização o levou de volta ao meio acadêmico, culminando em sua aprovação como estagiário de pós-graduação no Gabinete do Promotor de Justiça do MPMG, onde enriqueceu ainda mais seu conhecimento jurídico e prático.
            
            Atualmente, como advogado inscrito na OAB/MG nº 201.547, Adriano se destaca por sua carreira variada e por suas especializações: uma em Ministério Público Constitucional: Estrutura e Atuação Efetiva, pela FESMPMG, e outra em Direito Médico e Sanitário, pela FEMPRS. Essas especializações refletem seu compromisso contínuo com a excelência e sua dedicação.`
            },
        {
            name: 'Jaqueline da Silva Oliveira',
            profile: avatar3,
            profile2: avatar4,
            tel: '31998626959',
            designation: 'Advogada. Professora Universitária. Especialista em Direito',
            biografy: `Jaqueline da Silva Oliveira, sócia-diretora do Escritório Oliveira e Silva, sediado em Sete Lagoas, Minas Gerais, e registrada na OAB/MG sob o nº 178.166, possui uma carreira distinta, pautada na responsabilidade, ética, discrição e profissionalismo. Seu início no setor privado foi marcado pela capacidade excepcional de liderar e gerir tanto empresas quanto pessoas, estabelecendo um padrão de excelência em cada função que exerceu. Avançando em sua trajetória, atuou como assessora jurídica de parlamentar, adquirindo profunda compreensão sobre o funcionamento dos Poderes Legislativo e Executivo no âmbito municipal.

            Atualmente, Jaqueline desempenha um papel crucial em uma prestigiada instituição de ensino superior privado em Sete Lagoas, Minas Gerais, como Vice Assessora Acadêmica e Administrativa. Nesta posição, ela combina sua paixão pelo direito com seu compromisso com a educação, contribuindo significativamente para o desenvolvimento acadêmico e administrativo da faculdade. Além de sua especialização em Direito Penal e Processual, Jaqueline também compartilha seu conhecimento e experiência como professora na mesma área, enriquecendo o aprendizado de seus alunos com sua expertise jurídica e prática profissional.`
        
        }
    ]
    

    return (
        <>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="team">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-orange-600 text-base font-medium uppercase mb-2">CONHEÇA NOSSO TIME DE</h6>
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">ADVOGADOS</h3>

                        <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Venha conhecer o nosso time de advogados altamente qualificados e dedicados na Advocacia Oliveira e Silva. Com uma experiência consolidada de mais de 6 anos em Sete Lagoas, estamos prontos para proteger e defender seus direitos fundamentais.</p>
                    </div>
                    {/* <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-6 mt-8"> */}
                    <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-6 mt-8">
                        {team.map((member, index) => (
                            <div key={index} className="relative rounded-md shadow-lg overflow-hidden group">
                                <img  src={currentAvatar === avatar1 ? member.profile : member.profile2} className="group-hover:origin-center group-hover:scale-105 transition duration-500" alt="" />
                                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 z-0 transition duration-500"></div>
                                <ul className="list-none absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 mb-0 transition-all duration-500">
                                    <li className="mb-1"><Link to="mailto:contato@advocaciaoliveiraesilva.adv.br" className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i className="uil uil-mailbox"></i></Link></li>
                                    <li className="mb-1"><Link to={`https://wa.me/+55${member.tel}`} target="_blank" className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i className="uil uil-whatsapp"></i></Link></li>
                                    <li className="mb-1"><Link to={`tel:${member.tel}`} className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i className="uil uil-phone"></i></Link></li>
                                </ul>

                                <div className="content absolute start-4 end-4 bottom-4 bg-white dark:bg-slate-900 opacity-0 group-hover:opacity-100 p-4 rounded-md text-center transition-all duration-500">
                                    <Link to={`https://wa.me/+55${member.tel}`} target="_blank" className="h5 text-md dark:text-white hover:text-orange-600 dark:hover:text-orange-600 font-medium">{member.name}</Link>
                                    <h6 className="text-slate-400 dark:text-slate-300 mb-0 font-light">{member.designation}</h6>
                                    <div><button className="btn bg-orange-600 mt-4 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-sm rounded-full" onClick={() => openModal(member)}>Ler mais</button></div>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                
                {/* <!--end container--> */}
            </section>



            {modalIsOpen && selectedMember && (
                <div className="fixed inset-0 overflow-hidden flex justify-center items-center z-50">
                    <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={closeModal}></div>
                    <div className="absolute bg-white p-8 rounded-lg shadow-lg max-w-md" style={{ marginTop: "5%", maxHeight: '82vh', height: '100vh', width: '80%' }}>
                        <button onClick={closeModal} className="absolute top-4 right-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div className="flex items-center mb-4">
                            <div>
                                <h2 className="text-lg font-bold">{selectedMember.name}</h2>
                                <h3 className="text-md font-medium">{selectedMember.designation}</h3>
                            </div>
                        </div>
                        <div style={{ maxHeight: "calc(80vh - 120px)", overflowY: "auto", marginBottom: 50 }}>
                            <p className="text-gray-700 mt-4">{selectedMember.biografy}</p>
                        </div>
                    </div>
                </div>
            )}

                

        </>
    )
}
