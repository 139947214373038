import React from "react";
import { Link } from 'react-router-dom';

export default function Contact() {
    const handleMapClick = () => {
        const destination = 'R.+Gabi%C3%BAba,+221+-+Em%C3%ADlia,+Sete+Lagoas+-+MG,+35701-368/@-19.446139,-44.2918828,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0xa7dae24f499998e5:0x1cac73f32ea3363a!2m2!1d-44.2218423!2d-19.4461524?entry=ttu';
        const origin = 'Current+Location';

        // Redirect the user to Google Maps with the route calculation.
        window.location.href = `https://www.google.com/maps/dir/${origin}/${destination}`;
    };

    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Obtém os valores do formulário
      const name = e.target.name.value;
      const tel = e.target.tel.value;
      const email = e.target.email.value;
      const message = e.target.message.value;
  
      // Constrói a mensagem com os dados do formulário
      const whatsappMessage = `Olá! Me chamo ${name}. Meu telefone é ${tel}. Meu email é ${email}. A mensagem que gostaria de enviar é: ${message}`;
  
      // Codifica a mensagem para a URL
      const encodedMessage = encodeURIComponent(whatsappMessage);
  
      // Número de telefone para o qual você deseja enviar a mensagem (com o prefixo internacional)
      const phoneNumber = "553799361354";
  
      // URL do WhatsApp Web com o número de telefone e a mensagem codificada
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  
      // Redireciona para o WhatsApp Web
      window.location.href = whatsappURL;
    };
    
  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
        <div className="container">
          <div className="grid grid-cols-1 pb-8">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Vamos Conversar ?</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Contato</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="md:col-span-1">
            <div  style={{ backgroundColor: 'white'}} className="border border-gray-300 rounded-md p-3">
              
              <form style={{ paddingInline: 7}} className="space-y-4 mt-4" onSubmit={handleSubmit}>
                <h2 style={{fontSize: 19, color: 'text-state-400'}} className="ml-1 mb-10">Formulário Contato</h2>
                <div>
                  <label htmlFor="name" className="ml-1 block text-sm font-medium text-gray-700 dark:text-white">Nome</label>
                  <input style={{ border: '1px solid #ccc' }} placeholder="Insira seu nome" type="text" name="name" id="name" autoComplete="name" className="mt-1 h-10 p-3 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-gray-700 dark:text-white dark:border-gray-600 rounded-md"/>
                </div>
                <div>
                  <label htmlFor="tel" className="ml-1 block text-sm font-medium text-gray-700 dark:text-white">Telefone</label>
                  <input style={{ border: '1px solid #ccc' }} type="tel" placeholder="Insira seu telefone" name="tel" id="tel" autoComplete="tel" className="mt-1 h-10 p-3 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-gray-700 dark:text-white dark:border-gray-600 rounded-md"/>
                </div>
                <div>
                  <label htmlFor="email" className="ml-1 block text-sm font-medium text-gray-700 dark:text-white">Email</label>
                  <input style={{ border: '1px solid #ccc' }} type="email" placeholder="Insira seu email" name="email" id="email" autoComplete="email" className="mt-1 h-10 p-3 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-gray-700 dark:text-white dark:border-gray-600 rounded-md"/>
                </div>
                <div>
                  <label htmlFor="message" className="ml-1 block text-sm font-medium text-gray-700 dark:text-white">Mensagem</label>
                  <textarea style={{ border: '1px solid #ccc' }} id="message" placeholder="Insira sua mensagem" name="message" rows="4" className="mt-1 p-3 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-gray-700 dark:text-white dark:border-gray-600 rounded-md"></textarea>
                </div>
                <div className="flex justify-end">
                  <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">Enviar</button>
                </div>
              </form>
            </div>
          </div>

            {/* Contact Information */}
            <div className="md:col-span-1 lg:ms-8">
              <div className="flex">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                </div>
                <div className="flex-1 ms-6">
                  <h5 className="text-lg dark:text-white mb-2 font-medium">WhatsApp</h5>
                  <a href="https://wa.me/553799361354" className="text-slate-400">(37) 9936-1354</a>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                </div>
                <div className="flex-1 ms-6">
                  <h5 className="text-lg dark:text-white mb-2 font-medium">Email</h5>
                  <a href="mailto:contato@advocaciaoliveiraesilva.adv.br" className="text-slate-400">contato@advocaciaoliveiraesilva.adv.br</a>
                </div>
              </div>
              <div className="flex mt-4">
                  <div className="icons text-center mx-auto">
                      <i className="uil uil-clock block rounded text-2xl dark:text-white mb-0"></i>
                  </div>
                  <div className="flex-1 ms-6">
                      <h5 className="text-lg dark:text-white mb-2 font-medium">Horário</h5>
                      <p className="text-slate-400 mb-0">Segunda a Sexta: 07:00 - 18:00</p>
                      <p className="text-slate-400 mb-0">Sabado e Domingo: 24 Hrs</p>
                  </div>
              </div>

              <Link target="_blank" onClick={handleMapClick}>
              <div className="flex mt-4">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                </div>
                <div className="flex-1 ms-6">
                  <h5 className="text-lg dark:text-white mb-2 font-medium">Localização</h5>
                  <p className="text-slate-400 mb-2">Sete Lagoas - MG, 35701-368</p>
                  <p className="text-slate-400 mb-2">Paraopeba - MG, 35774-000</p>
                  <p className="text-slate-400 mb-2">Caetanópolis - MG, 35770-000</p>
                  <p className="text-slate-400 mb-2">Pirapora - MG, 39270-000</p>
                  <p className="text-slate-400 mb-2">Matozinhos - MG, 35720-000</p>
                  <p className="text-slate-400 mb-2">Pompéu - MG, 35640-000</p>
                </div>
              </div>
              </Link>

              {/* <div className="flex mt-4">
                  <div className="icons text-center mx-auto">
                      <i className="uil uil-share block rounded text-2xl dark:text-white mb-0"></i>
                  </div>
                  <div className="flex-1 ms-6">
                      <h5 className="text-lg dark:text-white mb-2 font-medium">Redes Sociais</h5>
                      <div style={{ display: 'flex'}}>
                        <a href="https://www.instagram.com/adv_oliveiraesilva" target="_blank" rel="noopener noreferrer">
                          <i className="uil mr-2 uil-instagram block rounded text-2xl dark:text-white mb-0"></i>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61553413335399" target="_blank" rel="noopener noreferrer">
                          <i className="uil mr-2 uil-facebook block rounded text-2xl dark:text-white mb-0"></i>
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=553799361354" target="_blank" rel="noopener noreferrer">
                          <i className="uil mr-2 uil-whatsapp block rounded text-2xl dark:text-white mb-0"></i>
                        </a>
                        <a href="mailto:contato@advocaciaoliveiraesilva.adv.br" target="_blank" rel="noopener noreferrer">
                          <i className="uil mr-2 uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                        </a>
                        <a href="tel:+553799361354" target="_blank" rel="noopener noreferrer">
                          <i className="uil mr-2 uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                        </a>
                      </div>
                  </div>
              </div>              */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
