import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/BlogPost.css';


export default function Blog() {

  const [blog, setBlogPosts] = useState([]);

  useEffect(() => {
    let isMounted = true;
  
    const fetchData = () => {
      fetch(`https://servidor-backend-oliveira-silva.vercel.app/api/blog`)
        .then(response => response.json())
        .then(data => {
          if (isMounted) {
            if (data.length > 0) {
              setBlogPosts(data);
            } else {
              fetchData();
            }
          }
        })
        .catch(error => console.error('Erro ao buscar posts do blog:', error));
    };
  
    fetchData();
    
    setInterval(fetchData(), 2000);
  
    return () => {
      isMounted = false;
    };
  }, []);
  
  


  return (
    <>
      <section className="relative md:py-24 py-16" id="blog">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Publicações</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">ÚLTIMAS NOTÍCIAS</h3>
            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Explore as últimas novidades e insights jurídicos no blog da Advocacia Oliveira e Silva. Mantenha-se informado sobre as atualizações legais, análises de casos relevantes e dicas valiosas para compreender o cenário jurídico atual.</p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 mt-8">
            {blog.map((item, key) => (
              <div key={key} className="blog relative rounded-md shadow shadow-slate-200 dark:shadow-slate-800 overflow-hidden">
                <img src={item.image} alt="" />
                <div className="content p-6">
                  <Link to={`/blog/${encodeURIComponent(item.url)}`} className="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium">{item.title}</Link>
                  <p className="text-slate-400 mt-3 text-limit" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                  <div className="mt-5">
                    <Link to={`/blog/${encodeURIComponent(item.url)}`} className="btn btn-link hover:text-orange-600 dark:hover:text-orange-600 after:bg-orange-600 dark:text-white transition duration-500">Leia Mais<i className="uil uil-arrow-right"></i></Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
