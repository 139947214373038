import React from "react";


import "../../node_modules/react-modal-video/scss/modal-video.scss";

export default function About() {

  const styleTextAbout = "text-slate-700";
  const styleTextAbout2 = {marginBottom: 22};

  return (
    <>
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto">
          <div>
            <div className="lg:col-span-5">
              
            </div>
            {/* end col */}

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Conheça</h6>
                <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Nossa História</h3>
                <p style={styleTextAbout2} className={styleTextAbout}>O Escritório Oliveira e Silva Advocacia, fundado há mais de seis anos em Sete Lagoas, é reconhecido como um dos principais escritórios de advogados da região. Combinando experiência, ética, planejamento, organização, competência e um forte comprometimento com seus clientes, o escritório tornou-se um ponto de referência no cenário jurídico de Sete Lagoas.</p>
                <p style={styleTextAbout2}  className={styleTextAbout}>Em Sete Lagoas, a vasta experiência de sua fundadora, que inclui passagens significativas pelos setores privado e público, enriquece profundamente a compreensão do sistema judiciário brasileiro. Como advogada experiente em Sete Lagoas, ela oferece uma perspectiva única e abrangente para seus clientes, contando com a colaboração indispensável de sua equipe de advogados.</p>
                <p style={styleTextAbout2}  className={styleTextAbout}>Jaqueline da Silva Oliveira, sócia-diretora do escritório, é uma advogada em Sete Lagoas conhecida por sua habilidade jurídica excepcional, organização, planejamento, integridade, humanidade e dedicação incansável à proteção e à concretização dos direitos dos seus clientes. Sua liderança inovadora tem sido um diferencial na orientação dos advogados do escritório Oliveira e Silva.</p>                <p style={styleTextAbout2}  className={styleTextAbout}>Reconhecido por uma abordagem ética e centrada no cliente, o escritório oferece soluções jurídicas personalizadas em diversos temas do direito. O escritório não só oferece serviços jurídicos de alta qualidade, mas também demonstra um compromisso firme com a comunidade local. Participando ativamente em Sete Lagoas através de advocacia pro bono e advocacia dativa, os advogados do escritório reforçam o vínculo com a cidade e promovem a justiça social.</p>
                <p style={styleTextAbout2}  className={styleTextAbout}>Com uma trajetória marcada por organização, integridade e inovação, o Escritório de Advocacia Oliveira e Silva se estabelece como líder entre os advogados em Sete Lagoas, pronto para atender às suas necessidades legais com eficiência e dedicação. Este escritório é sua escolha ideal para representação jurídica competente e confiável em Sete Lagoas, destacando-se pela ética, organização, planejamento e dedicação em todos os aspectos de sua atuação.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End --> */}
    </>
  );

}

